import { get, post } from 'services';
import routes from './routes';

const BillServices = {
  getBillMonths: async (params) => {
    const data = await get(routes.getBillMonths, params);
    return data;
  },
  getBills: async (params) => {
    const data = await get(routes.getBills, params);
    return data;
  },
  getBillDetailById: async (params) => {
    const data = await get(routes.getBillDetailById, params);
    return data;
  },
  importBill: async (obj) => {
    const data = await post(routes.importBill, obj);
    return data;
  },
  sendEmailToAllMembers: async (obj) => {
    const data = await post(routes.sendEmailToAllMembers, obj);
    return data;
  },
  sendEmailToSingleMember: async (obj) => {
    const data = await post(routes.sendEmailToSingleMember, obj);
    return data;
  },
  removeBill: async (obj) => {
    const data = await post(routes.removeBill, obj);
    return data;
  },
}

export default BillServices