import { LoadingButton } from "@mui/lab";
import { Button, IconButton, Tooltip } from "@mui/material";
import Colors from "assets/Style/Colors";

export const PrimaryButton = (props) => {

  return (
    <LoadingButton
      variant="contained"
      {...props}
      sx={{
        background: props.disabled ? Colors.flashWhite : props.btnColor,
        color: props.disabled ? Colors.smokyBlack : Colors.white,
        textTransform: 'capitalize',
        boxShadow: 'none',
        minWidth: '120px',
        '&:hover': {
          background: props.disabled ? Colors.flashWhite : props.btnColor,
          color: props.disabled ? Colors.smokyBlack : Colors.white,
        }
      }}
    >
      {props.title}
    </LoadingButton>
  );
}

export const SecondaryButton = (props) => {

  const { title } = props

  return (
    <LoadingButton
      variant="contained"
      {...props}
      sx={{
        background: Colors.flashWhite,
        color: Colors.gunMetal,
        minWidth: '120px',
        textTransform: 'capitalize',
        '&:hover': {
          background: Colors.flashWhite,
          color: Colors.gunMetal,
          boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
        }
      }}
    >
      {title}
    </LoadingButton>
  );
}

export const ActionButton = (props) => {
  return (
    <Tooltip title={props?.title}>
      <IconButton {...props} sx={{ mx: 0.5, p: 0 }}>
        {props.children}
      </IconButton>
    </Tooltip>
  );
}

export const PaginationButton = (props) => {
  return (
    <Button
      {...props}
      variant="contained"
      sx={{
        py: 0.5,
        mx: 0.5,
        textTransform: 'capitalize',
        bgcolor: Colors.white,
        color: Colors.black,
        '&:hover': { bgcolor: Colors.white, color: Colors.black }
      }}
    >
      {props.children}
    </Button>
  );
}
