import { GolfCourseOutlined, GroupAddOutlined, GroupOutlined, GroupsOutlined, HomeOutlined, NewspaperOutlined, ReceiptOutlined } from "@mui/icons-material"

const Navigations = [
	{
		label: 'Home',
		icon: <HomeOutlined />,
		path: '/',
	},
	{
		label: 'Members',
		icon: <GroupsOutlined />,
		path: '/members',
		childPath: ['/import-member', '/member-list'],  // must have to enter child path here if item has children
		children: [
			{
				label: 'Import Members',
				icon: <GroupAddOutlined />,
				path: '/import-member'
			},
			{
				label: 'Members List',
				icon: <GroupOutlined />,
				path: '/member-list'
			},
		]
	},
	{
		label: 'Bills',
		icon: <ReceiptOutlined />,
		path: '/bills',
		childPath: ['/import-bill', '/bill-list'],  // must have to enter child path here if item has children
		children: [
			{
				label: 'Import New Bills',
				icon: <ReceiptOutlined />,
				path: '/import-bill'
			},
			{
				label: 'Bills List',
				icon: <ReceiptOutlined />,
				path: '/bill-list'
			},
		]
	},
	{
		label: 'Handicaps',
		icon: <GolfCourseOutlined />,
		path: '/handicaps',
		childPath: ['/import-handicap', '/handicap-list'],  // must have to enter child path here if item has children
		children: [
			{
				label: 'Import Handicaps',
				icon: <GolfCourseOutlined />,
				path: '/import-handicap'
			},
			{
				label: 'Handicaps List',
				icon: <GolfCourseOutlined />,
				path: '/handicap-list'
			},
		]
	},
	{
		label: 'News',
		icon: <NewspaperOutlined />,
		path: '/news',
		childPath: ['/add-news', '/news-list'],  // must have to enter child path here if item has children
		children: [
			{
				label: 'Add News',
				icon: <NewspaperOutlined />,
				path: '/add-news'
			},
			{
				label: 'News List',
				icon: <NewspaperOutlined />,
				path: '/news-list'
			},
		]
	},
]

export default Navigations