import React, { lazy } from "react";

const Dashboard = lazy(() => import('pages/Dashboard'));
const ImportMember = lazy(() => import('pages/Members/ImportMember'));
const MemberList = lazy(() => import('pages/Members/MemberList'));
const ImportBill = lazy(() => import('pages/Bills/ImportBill'));
const BillList = lazy(() => import('pages/Bills/BillList'));
const ImportHandicap = lazy(() => import('pages/Handicaps/ImportHandicap'));
const HandicapList = lazy(() => import('pages/Handicaps/HandicapList'));
const AddNews = lazy(() => import('pages/News/AddNews'));
const NewsList = lazy(() => import('pages/News/NewsList'));

const AdminRoutes = [
  {
    path: "/",
    component: <Dashboard />,
  },
  {
    path: "/members/import-member",
    component: <ImportMember />,
  },
  {
    path: "/members/member-list",
    component: <MemberList />,
  },
  {
    path: "/bills/import-bill",
    component: <ImportBill />,
  },
  {
    path: "/bills/bill-list",
    component: <BillList />,
  },
  {
    path: "/handicaps/import-handicap",
    component: <ImportHandicap />,
  },
  {
    path: "/handicaps/handicap-list",
    component: <HandicapList />,
  },
  {
    path: "/news/add-news",
    component: <AddNews />,
  },
  {
    path: "/news/news-list",
    component: <NewsList />,
  },
]

export default AdminRoutes
