const routes = {
  getBillMonths: 'getBillMonths',
  getBills: 'getBills',
  getBillDetailById: 'getBillDetailById',
  importBill: 'createBill',
  sendEmailToAllMembers: 'sendBillingEmailToMembers',
  sendEmailToSingleMember: 'sendBillingEmailByMemberId',
  removeBill: 'deleteBillByMonth',
};

export default routes